<template>
  <div class="container" style="padding: 10px">
    <div class="columns is-centered">
      <div class="column is-12">
        <img src="../../assets/logo.svg">
      </div>
    </div>
    <div class="columns is-centered">
      <a @click="$ml.change('ru')" title="Русский" style="margin-right: 5px">
        <img src="../../assets/ru.png">
      </a>
      <a @click="$ml.change('en')" title="English">
        <img src="../../assets/en.png">
      </a>
    </div>
    <form @submit.prevent="login" novalidate>
      <b-loading :is-full-page="false" v-model="formLoading" :can-cancel="false"></b-loading>
      <div class="columns is-centered">
        <div class="column is-4">
          <b-notification type="is-success is-light" aria-close-label="Close notification" v-if="this.$route.query.c">
            <p v-text="$ml.get('login_confirmation_text')"></p>
          </b-notification>
          <b-notification type="is-success is-light" aria-close-label="Close notification" v-if="this.$route.query.r">
            <p v-text="$ml.get('login_reset_text')"></p>
          </b-notification>
          <b-notification type="is-success is-light" aria-close-label="Close notification" v-if="this.$route.query.np">
            <p v-text="$ml.get('login_newpass_text')"></p>
          </b-notification>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-4">
          <b-field
              label="Email" label-position="on-border"
              :message="'login' in errors ? errors['login'] : ''"
              :type="'login' in errors ? 'is-danger' : ''">
            <b-input v-model="form.login" icon="email"></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-4">
          <b-field :label="$ml.get('login_password')" label-position="on-border">
            <b-input type="password" v-model="form.password" icon="lock"></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-4">
          <vue-recaptcha ref="recaptcha" sitekey="6LdqpCUcAAAAAMUeYmQTFuK7Z9CBrzqVwy9sC9h_" @verify="onVerify" :loadRecaptchaScript="true">
          </vue-recaptcha>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-4">
          <ul class="horizontal">
            <li>
              <b-button :disabled="submitEnabled" type="is-info" native-type="submit">
                {{ $ml.get('login_login') }}
              </b-button>
            </li>
            <li style="padding: 10px 5px 0 60px">
              <router-link :to="{ path: `/signup` }">{{ $ml.get('login_signup') }}</router-link>
            </li>
            <li style="padding: 10px 0 0 0">
              |
            </li>
            <li style="padding: 10px 0 0 5px">
              <router-link :to="{ path: `/reset` }">{{ $ml.get('login_forgotpass') }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import router from "@/router";
import VueRecaptcha from 'vue-recaptcha'

export default {
  name: "Auth",
  components: {VueRecaptcha},
  data() {
    return {
      form: {
        login: '',
        password: '',
      },
      errors: {},
      formLoading: false,
      captcha: false,
    }
  },
  computed: {
    submitEnabled() {
      return !this.form.login || !this.form.password || !this.captcha
    }
  },
  methods: {
    onVerify: function (response) {
      if (response) this.captcha = true;
    },
    login: function () {
      this.formLoading = true
      this.$store.dispatch('auth/login', this.form).then(() => {
        this.$store.dispatch('data/constants').then(() => {
          this.$store.dispatch('auth/user_pulse').then(() => {
            router.push('/');
          })
        })
      }).catch(error => {
        this.errors = error.response.data.result
      }).finally(() => {
        this.formLoading = false
      })
    },
    confirmation: function (code) {
      this.formLoading = true
      this.$store.dispatch('auth/confirmation', {code: code}).then(() => {
        this.$store.dispatch('data/constants').then(() => {
          this.$store.dispatch('auth/user_pulse').then(() => {
            router.push('/');
          })
        })
      }).catch(error => {
      }).finally(() => {
        this.formLoading = false
      })
    },
  },
  mounted() {
    if (this.$route.query.cnf) {
      this.confirmation(this.$route.query.cnf)
    }
  }
}
</script>
<style scoped>
img {
  max-width: 300px;
}
</style>